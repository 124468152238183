import * as React from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  addDays,
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isSameDay,
  isToday,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import toast from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import {
  CoachAvailabilityStatusesEnum,
  GetCoachBookingPageQuery,
  useGetUniqueCoachVenueCourtsLazyQuery,
} from 'types/generated/client';
import { LocationType, VenueAddress, formatCoachVenueData } from 'utils/shared/coachBuilder';
import { isSlotOverlapping, mergeDateAndSlot } from 'utils/shared/date/mergeDateAndSlot';
import { formatUrl } from 'utils/shared/formatUrl';
import { getTimezone } from 'utils/shared/time/getTimezone';
import { CalendarEvent, useCoachCalendarEvents } from 'hooks/useCoachCalendarEvents';
import { useMobileView } from 'hooks/useMobileView';
import ChevronLeft from 'svg/ChevronLeft';
import ChevronRight from 'svg/ChevronRight';
import InfoCircle from 'svg/InfoCircle';
import { Coach, CurrentVenueCourtState, Lessons } from 'screens/CoachDetails/types';
import Button, { ButtonLink } from 'components/Button';
import classNames from 'styles/utils/classNames';
import VenueSlider from './VenueSlider';
import { generateTimeSlots } from './utils';

type CoachAvailabilityType = GetCoachBookingPageQuery['userProfiles'][0]['coachAvailability'];

interface TimePickerProps {
  setPickingState: React.Dispatch<React.SetStateAction<PickingStates | null>>;
  selectedDate: Date | number;
  selectTimeSlot: (day: Date, time: string, courtVenue: CurrentVenueCourtState | null) => void;
  coach: Coach;
  lessons: Lessons;
  coachAvailability: any;
  selectedVenues: Map<string, VenueAddress>;
}

interface timeSlot {
  time: string;
  venue: any;
}

const TimePicker: React.FC<TimePickerProps> = ({
  selectedDate,
  selectTimeSlot,
  coach,
  lessons,
  coachAvailability,
  selectedVenues,
}) => {
  const [timeSlots, setTimeSlots] = useState<
    { date: Date; slots: timeSlot[]; startTime: string; startTimeAMPM: string }[]
  >([]);

  const {
    events: coachGoogleEvents,
    loading: googleEventsLoading,
    fetchEvents,
  } = useCoachCalendarEvents({
    coachId: coach?.id || '',
  });

  useEffect(() => {
    if (coach?.id && selectedDate) {
      const endDate = addDays(selectedDate, 4);
      fetchEvents(selectedDate as Date, endDate);
    }
  }, [coach?.id, selectedDate, fetchEvents]);

  const isSlotBooked = (slot: string, date: Date) => {
    const mergedDateSlot = mergeDateAndSlot(date, slot);
    const isBookedInApp = isSlotOverlapping(mergedDateSlot, lessons);

    if (isBookedInApp) return true;

    if (coachGoogleEvents && coachGoogleEvents.length > 0) {
      const slotStartDate = mergeDateAndSlot(date, slot);

      const slotEndDate = new Date(slotStartDate.getTime() + 60 * 60 * 1000);

      return coachGoogleEvents.some((event: CalendarEvent) => {
        if (!event.start?.dateTime || !event.end?.dateTime) return false;

        const eventStart = new Date(event.start.dateTime);
        const eventEnd = new Date(event.end.dateTime);

        return (
          (slotStartDate >= eventStart && slotStartDate < eventEnd) ||
          (slotEndDate > eventStart && slotEndDate <= eventEnd) ||
          (slotStartDate <= eventStart && slotEndDate >= eventEnd)
        );
      });
    }

    // If no Google Calendar events or no overlaps found
    return false;
  };

  useEffect(() => {
    if (selectedDate && coachAvailability?.length > 0) {
      const newTimeSlots = Array.from({ length: 5 }, (_, i) => {
        const date = addDays(selectedDate, i);
        const dayOfWeek = format(date, 'EEEE').toUpperCase();

        const dayAvailabilities = coachAvailability.filter(
          (slot: any) => slot.dayOfWeek === dayOfWeek,
        );

        if (dayAvailabilities.length > 0) {
          const daySlots = dayAvailabilities.flatMap((availability: any) =>
            generateTimeSlots(
              availability.startTimeMinutes / 60,
              availability.endTimeMinutes / 60,
              15,
              'hh:mm a',
              getTimezone(coach?.timezone),
              60,
              selectedVenues.get(
                availability?.coachAvailabilityVenueId?.id ||
                  availability?.coachAvailabilityCustomCourt?.id ||
                  '',
              ) || null,
            ),
          );
          return { date, slots: daySlots };
        } else {
          return { date, slots: [] }; // No availability for this day
        }
      });

      setTimeSlots(newTimeSlots as any);
    }
  }, [selectedDate, coachAvailability]);

  return (
    <div className="relative overflow-y-auto pb-5">
      {timeSlots.map((daySlot, index) => {
        return (
          <React.Fragment key={`day-${index}`}>
            <div
              className={classNames(
                'sticky top-0 z-10 flex items-center bg-color-bg-lightmode-primary px-6 pb-4 dark:bg-color-bg-darkmode-primary lg:pb-4',
                index > 0 && 'mt-4',
              )}
            >
              <p className="typography-product-heading-compact text-color-text-brand dark:text-color-text-brand">
                {format(daySlot.date, 'EEEE, d MMMM')}
              </p>
            </div>

            <div className="mx-6 rounded-md border">
              {daySlot?.slots?.length > 0 &&
                daySlot.slots.map(({ time, venue }, idx) => {
                  const slotBooked = isSlotBooked(time, daySlot.date);
                  return (
                    <div
                      key={`slot-${index}-${idx}`}
                      className={classNames(
                        'typography-product-body flex items-center justify-between border-b border-color-border-input-lightmode px-4 py-3 text-color-text-lightmode-primary dark:border-color-border-input-darkmode dark:text-color-text-darkmode-primary',
                        {
                          'bg-color-bg-lightmode-secondary': isSameDay(daySlot.date, selectedDate),
                        },
                      )}
                    >
                      <div className="inline-flex items-center gap-1">
                        <InfoCircle
                          className="h-5 w-5"
                          data-tooltip-id={`address-info-${idx}-${index}`}
                        />
                        <Tooltip
                          id={`address-info-${idx}-${index}`}
                          content={venue?.address}
                          place="bottom-start"
                        />

                        <span className="whitespace-nowrap">{time}</span>
                      </div>

                      <Button
                        variant="secondary"
                        isInline
                        size="sm"
                        onClick={() => {
                          selectTimeSlot(daySlot.date, time, {
                            id: venue?.id || '',
                            address: venue?.address || '',
                            title: venue?.title || '',
                            locationType: venue?.locationType!,
                          });
                        }}
                        disabled={slotBooked}
                        loading={googleEventsLoading}
                      >
                        {slotBooked ? 'Booked' : 'Choose'}
                      </Button>
                    </div>
                  );
                })}
              {daySlot?.slots?.length === 0 && (
                <div
                  key={`slot-${index}`}
                  className={
                    'typography-product-body flex items-center justify-center rounded-md border-b border-color-border-input-lightmode px-4 py-3 text-color-text-lightmode-primary dark:border-color-border-input-darkmode dark:text-color-text-darkmode-primary'
                  }
                >
                  No slots available
                </div>
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

interface DatePickerProps {
  onChange: (date: Date) => void;
  coach: Coach;
}

const DatePicker: React.FC<DatePickerProps> = ({ onChange, coach }) => {
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Ensure the time is set to 00:00:00 for today

  const nextDay = new Date(today);
  nextDay.setDate(today.getDate() + 1);

  nextDay.setHours(0, 0, 0, 0);

  const startOfCurrentMonth = startOfMonth(currentDate);
  const endOfCurrentMonth = endOfMonth(currentDate);
  const startOfWeekForCurrentMonth = startOfWeek(startOfCurrentMonth);
  const endOfWeekForCurrentMonth = endOfWeek(endOfCurrentMonth);

  const days = eachDayOfInterval({
    start: startOfWeekForCurrentMonth,
    end: endOfWeekForCurrentMonth,
  });

  // Find the first upcoming Sunday
  // const currentDay = startOfWeek(addDays(today, 0), { weekStartsOn: 0 });
  const currentDay = addDays(nextDay, 0);

  const handlePrevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const handleDaySelect = (day: Date) => {
    if (day >= currentDay && isDaySelectable(day) && !coach?.coachExternalBookingUrl) {
      setSelectedDate(day);
      onChange(day);
    } else if (coach?.coachExternalBookingUrl) {
      window.open(formatUrl(coach?.coachExternalBookingUrl), '_blank');
    }
  };

  const isDaySelectable = (day: Date) => {
    const dayOfWeek = format(day, 'EEEE').toUpperCase();
    const coachAvailability = coach?.coachAvailability as CoachAvailabilityType;
    const isAvailable = coachAvailability.some(
      (slot) =>
        slot.status === CoachAvailabilityStatusesEnum.Active && slot.dayOfWeek === dayOfWeek,
    );

    // Allow selection if the day is available or if it's today
    return isAvailable || isToday(day);
  };

  return (
    <div className="flex flex-col items-center p-4">
      <div className="mb-4 flex w-full items-center justify-between px-4">
        <Button
          variant="inverted"
          className="border-none"
          isInline
          iconLeft={<ChevronLeft className="h-4 w-4 cursor-pointer" />}
          onClick={handlePrevMonth}
        />
        <span className="typography-product-subheading mx-4 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
          {format(currentDate, 'MMMM yyyy')}
        </span>
        <Button
          variant="inverted"
          className="border-none"
          isInline
          iconLeft={<ChevronRight className="h-4 w-4 cursor-pointer" />}
          onClick={handleNextMonth}
        />
      </div>
      <div className="grid w-full grid-cols-7 gap-1">
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
          <div key={day} className="py-2 text-center">
            {day}
          </div>
        ))}
        {days.map((day) => {
          const isSelectable = day >= currentDay && isDaySelectable(day);

          return (
            <div
              key={day.toString()}
              className={`pb-full relative col-auto
                          ${getDay(day) === 0 ? 'col-start-1' : ''}
                          before:block
                          before:pb-[100%]
                          before:content-['']`} // Creates a square by using padding-bottom
              onClick={() => isSelectable && handleDaySelect(day)}
            >
              <div
                className={classNames(
                  `absolute bottom-0 left-0 right-0 top-0 flex cursor-pointer items-center
                  justify-center rounded-full`,
                  format(day, 'MM') !== format(currentDate, 'MM')
                    ? 'text-color-text-lightmode-tertiary'
                    : '',
                  selectedDate !== null && isSameDay(day, selectedDate)
                    ? '!bg-color-bg-lightmode-brand text-color-text-lightmode-invert dark:text-color-text-darkmode-invert'
                    : '',
                  isToday(day) ? 'ring-1 ring-black' : '',
                  !isSelectable
                    ? 'cursor-not-allowed opacity-50'
                    : 'hover:bg-color-bg-lightmode-secondary',
                )}
              >
                {format(day, 'd')}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface CalendarDatePickerProps {
  selectedDate: Date;
  setSelectedDate: (selectedDate: Date) => void;
  selectTimeSlot: (day: Date, time: string, courtVenue: CurrentVenueCourtState | null) => void;
  coach: Coach;
  lessons: Lessons;
  venueFromMap?: VenueAddress | null;
  setVenueFromMap?: React.Dispatch<React.SetStateAction<VenueAddress | null>>;
  showTabSwitch?: boolean;
  setShowTabSwitch?: (showTabSwitch: boolean) => void;
  isCoach: boolean;
}

enum PickingStates {
  OnDatePicking = 'ON_DATE_PICKING',
  OnTimePicking = 'ON_TIME_PICKING',
}

export default function CalendarDatePicker({
  selectedDate,
  setSelectedDate,
  selectTimeSlot,
  coach,
  lessons,
  venueFromMap,
  setVenueFromMap,
  showTabSwitch,
  setShowTabSwitch,
  isCoach,
}: CalendarDatePickerProps) {
  const [selectedVenues, setSelectedVenues] = useState<Map<string, VenueAddress>>(new Map());

  const [pickingState, setPickingState] = React.useState<PickingStates | null>(null);

  const [venues, setVenues] = useState<VenueAddress[] | null>(null);

  const [getUniqueCoachVenueCourts, { data: uniqueCoachVenueCourtsData }] =
    useGetUniqueCoachVenueCourtsLazyQuery();

  const selectDate = () => {
    setPickingState(PickingStates.OnTimePicking);
  };

  const handleChangeDate = (value: Date) => {
    setSelectedDate(value);
    setPickingState(PickingStates.OnTimePicking);
  };

  const coachAvailability = React.useMemo(
    () =>
      coach.coachAvailability.filter((availability) => {
        const venueId = availability.coachAvailabilityVenueId?.id;
        const customCourtId = availability.coachAvailabilityCustomCourt?.id;
        return selectedVenues?.get(venueId || customCourtId);
      }),
    [selectedVenues, coach],
  );

  const getNearestAvailableDate = (coachAvailability: Coach['coachAvailability']): Date | null => {
    const currentDate = new Date();
    if (!coachAvailability || coachAvailability.length === 0) return null;

    for (let i = 0; i < 7; i++) {
      const dayToCheck = format(addDays(currentDate, i), 'EEEE').toUpperCase();
      const availabilityForDay = coachAvailability.find((item) => item.dayOfWeek === dayToCheck);

      if (availabilityForDay) {
        return addDays(currentDate, i);
      }
    }

    return null; // If no available day is found within the next 7 days
  };

  useEffect(() => {
    if (!venueFromMap || !coach.coachAvailability || coach.coachAvailability.length === 0) {
      setPickingState(PickingStates.OnDatePicking);
      return;
    }

    const nearestDate = getNearestAvailableDate(coach.coachAvailability);

    if (!nearestDate) {
      toast.error('No nearest available dates found');
      return;
    }
    setPickingState(PickingStates.OnTimePicking);
    setSelectedDate(nearestDate);

    const venuesMap = new Map<string, VenueAddress>();
    venuesMap.set(venueFromMap.id, venueFromMap);
    setSelectedVenues(venuesMap);
  }, [venueFromMap, coach]);

  useEffect(() => {
    const init = async () => {
      const data = await getUniqueCoachVenueCourts({
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: coach.id,
        },
      });
      if (data?.data) {
        const venues = formatCoachVenueData(data.data.coachAvailability);
        setVenues(venues);
      }
    };
    init();
  }, [uniqueCoachVenueCourtsData, coach.id]);

  useEffect(() => {
    // making all the venues selected by default
    if (!venues || venueFromMap) return;
    const venuesMap = new Map<string, VenueAddress>();
    venues.forEach((venue) => {
      venuesMap.set(venue.id, venue);
    });
    setSelectedVenues(venuesMap);
  }, [venues, venueFromMap]);

  useEffect(() => {
    if (setShowTabSwitch) {
      if (pickingState === PickingStates.OnDatePicking) {
        setShowTabSwitch(true);
      } else {
        setShowTabSwitch(false);
      }
    }
  }, [pickingState, setShowTabSwitch]);

  if (!pickingState) {
    return;
  }

  return (
    <div className="flex max-h-[calc(100vh-6rem)] flex-col overflow-y-hidden pt-5">
      {pickingState === PickingStates.OnDatePicking && (
        <p className="typography-product-heading-compact mb-4 px-6 xs:text-color-text-lightmode-primary lg:text-color-text-brand">
          Pick a date
        </p>
      )}

      {pickingState === PickingStates.OnDatePicking && (
        <>
          <DatePicker onChange={handleChangeDate} coach={coach} />
        </>
      )}
      {pickingState === PickingStates.OnTimePicking && (
        <>
          {venues && (
            <VenueSlider
              setPickingState={setPickingState}
              venues={venues}
              selectedVenues={selectedVenues}
              setSelectedVenues={setSelectedVenues}
              setVenueFromMap={setVenueFromMap}
            />
          )}
          <TimePicker
            setPickingState={setPickingState}
            selectedDate={selectedDate}
            selectTimeSlot={selectTimeSlot}
            coach={coach}
            lessons={lessons}
            coachAvailability={coachAvailability}
            selectedVenues={selectedVenues}
          />
        </>
      )}
      {pickingState === PickingStates.OnDatePicking &&
        (coach?.coachExternalBookingUrl ? (
          <div className="px-6 pb-5">
            <ButtonLink
              isExternal
              variant={'brand'}
              size="lg"
              isInline
              className="mt-4 w-full lg:w-full"
              href={formatUrl(coach?.coachExternalBookingUrl)}
              rel="noopener noreferrer nofollow ugc"
            >
              <span className="sm:hidden">Select</span>
              <span className="hidden sm:inline">Book a lesson</span>
            </ButtonLink>
          </div>
        ) : (
          <div className="px-6 pb-5">
            <Button
              variant={'brand'}
              size="lg"
              isInline
              className="mt-4 w-full lg:w-full"
              onClick={selectDate}
              disabled={!selectedDate}
            >
              <span className="sm:hidden">Select</span>
              <span className="hidden sm:inline">Book a lesson</span>
            </Button>
          </div>
        ))}
    </div>
  );
}

import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { calendar_v3 } from 'googleapis';

type GoogleEvent = calendar_v3.Schema$Event;
export type CalendarEvent = GoogleEvent & { calendarName: string };

interface FetchEventsResponse {
  events: CalendarEvent[];
}

interface UseCoachCalendarEventsProps {
  coachId: string;
}

export const useCoachCalendarEvents = ({ coachId }: UseCoachCalendarEventsProps) => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchEvents = useCallback(
    async (startDate: Date, endDate: Date) => {
      if (!coachId) {
        return [];
      }

      setLoading(true);
      try {
        // Format dates for API
        const formattedStart = format(startDate, "yyyy-MM-dd'T'00:00:00'Z'");
        const formattedEnd = format(endDate, "yyyy-MM-dd'T'23:59:59'Z'");

        const url = `/api/v1/google/coach-events?coachId=${encodeURIComponent(
          coachId,
        )}&startDate=${encodeURIComponent(formattedStart)}&endDate=${encodeURIComponent(
          formattedEnd,
        )}`;

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Error fetching coach events: ${response.statusText}`);
        }

        const data: FetchEventsResponse = await response.json();
        setEvents(data.events || []);
        return data.events || [];
      } catch (err) {
        console.error('Failed to fetch coach calendar events:', err);
        setError(err instanceof Error ? err : new Error('Unknown error occurred'));
        setEvents([]);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [coachId],
  );

  return {
    events,
    loading,
    error,
    fetchEvents,
  };
};
